.uk-background-blue {
  background-color: #18488f;
}

.app-header.uk-background-blue {
  background: rgb(24,72,143);
  background: -moz-linear-gradient(120deg, rgba(24,72,143,1) 0%, rgba(12,54,116,1) 100%);
  background: -webkit-linear-gradient(120deg, rgba(24,72,143,1) 0%, rgba(12,54,116,1) 100%);
  background: linear-gradient(120deg, rgba(24,72,143,1) 0%, rgba(12,54,116,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#18488f",endColorstr="#0c3674",GradientType=1);
}

h1 {
  font-size: 1.3rem;
}

h2 {
  font-size: 1.2rem;
}

h3 {
  font-size: 1rem;
}

.uk-logo img {
  max-height: 50px;
}

.uk-progress {
  height: 2px;
  margin-top: 0;
  background: #0c3674;
}

.uk-progress::-webkit-progress-bar {
  background: #0c3674;
}

.uk-light .uk-input:disabled {
  color: rgba(255,255,255,.3);
}

.uk-option-box {
  background: rgb(24,72,143);
  background: -moz-linear-gradient(120deg, rgba(24,72,143,1) 0%, rgba(12,54,116,1) 100%);
  background: -webkit-linear-gradient(120deg, rgba(24,72,143,1) 0%, rgba(12,54,116,1) 100%);
  background: linear-gradient(120deg, rgba(24,72,143,1) 0%, rgba(12,54,116,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#18488f",endColorstr="#0c3674",GradientType=1);
}

.uk-form-danger, .uk-form-danger:focus, .error-list {
  color: #FFD700 !important;
  border-color: #FFD700 !important;
}

.error-list, .info-list {
  font-size: 13px;
}

.info-list {
  padding-top: 5px;
  font-size: 14px;
}

.uk-checkbox {
  border: 1.5px solid #fff !important;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}